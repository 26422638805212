<template>
  <div>
    <div class="card mb-4 custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-7 mb-4">
            <b-dropdown id="dropdown-1" text="ประเภทสถานที่ตั้ง" class="dropdown_input_search mr-4 mb-2">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.location_type_filters" :key="v.id" style="padding: .5rem 1rem; font-size: 0.875rem;">
                    <input type="checkbox" class="checkbox_search_input" :name="`checkbox_group_name${v.id}`"
                      :id="`checkbox_group_name${v.id}`" v-model="v.selected" @change="searchHandler()"/>
                    <label class="form-check-label" v-bind:for="`checkbox_group_name${v.id}`">{{v.name}} ({{v.filter_count}})</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="dropdown-2" text="จังหวัด" class="dropdown_input_search mr-4 mb-2">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <b-form-input placeholder="ค้นหาชื่อจังหวัด" @keyup="filterCity" class="dropdown-form-control">
                </b-form-input>
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="(v) in criteria.city_filters" :key="v.id" style="padding: .5rem 1rem; font-size: 0.875rem;">
                    <input type="checkbox" class="checkbox_search_input" :name="`checkbox_province_name${v.id}`"
                      :id="`checkbox_province_name${v.id}`" v-model="v.selected" @change="selectCity(v)"/>
                    <label class="form-check-label" v-bind:for="`checkbox_province_name${v.id}`">{{v.name}} ({{v.filter_count}})</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>

            <b-dropdown id="dropdown-2" text="อำเภอ" class="dropdown_input_search mr-4 mb-2">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <b-form-input placeholder="ค้นหาชื่ออำเภอ" @keyup="filterDistrict" class="dropdown-form-control"></b-form-input>
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="(v) in criteria.district_filters" :key="v.id" style="padding: .5rem 1rem; font-size: 0.875rem;">
                    <input type="checkbox" class="checkbox_search_input" :name="`checkbox_district_name${v.id}`"
                      :id="`checkbox_district_name${v.id}`" v-model="v.selected" @change="selectDistrict(v)"/>
                    <label class="form-check-label" v-bind:for="`checkbox_district_name${v.id}`">{{v.name}} ({{v.filter_count}})</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="dropdown-2" text="ตำบล" class="dropdown_input_search mr-4 mb-2">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <b-form-input placeholder="ค้นหาชื่อตำบล" @keyup="filterSubDistrict" class="dropdown-form-control"></b-form-input>
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="(v) in criteria.subdistrict_filters" :key="v.id" style="padding: .5rem 1rem; font-size: 0.875rem;">
                    <input type="checkbox" class="checkbox_search_input" :name="`checkbox_subdistrict_name${v.id}`"
                      :id="`checkbox_subdistrict_name${v.id}`" v-model="v.selected" @change="selectSubDistrict(v)"/>
                    <label class="form-check-label" v-bind:for="`checkbox_subdistrict_name${v.id}`">{{v.name}} ({{v.filter_count}})</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
          </div>
          <div class="col-sm-5">
            <b-input-group >
              <b-input-group-append>
                <b-input-group-text  class="input_border_radius_left_top_bt">
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-append>
              <b-form-input placeholder="ระบุการค้นหา เช่น ประเภทสถานที่ตั้ง จังหวัด"
                            v-model="criteria.search"
                            class="form-control-group"
                            @keyup.enter.native="searchHandler()">
              </b-form-input>
              <b-input-group-append>
                <b-button size="md"
                          text="Button"
                          variant="primary"
                          @click="searchHandler()"
                          class="btn_border_radius_right_top_bt"
                          style="width: 86px;">
                  ค้นหา
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-12">
            <hr class="mt-5 mb-5">
          </div>
          <div class="col-lg-4 col-md-6">
            <span style="font-size: 12px; color: #848484; font-weight: lighter;" class="m-2">
              จำนวน: <strong style="color: #000000; font-weight: bold;">{{countFilter()}} รายการ</strong> จาก {{criteria.city_filters.length+defaultCityFilters.length | numberIntFormat}} รายการ
            </span>
          </div>
          <div class="col-md-6">
            <template v-for="(select, index) in criteria.location_type_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="index" v-if="select.selected">
                {{select.name}}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{select.filter_count}}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in defaultCityFilters">
              <span class="badge bage_selected_checkbox_search m-2" :key="index" v-if="select.selected">
                {{select.name}}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{select.filter_count}}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in defaultDistrictFilters">
              <span class="badge bage_selected_checkbox_search m-2" :key="index" v-if="select.selected">
                {{select.name}}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{select.filter_count}}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in defaultSubDistrictFilters">
              <span class="badge bage_selected_checkbox_search m-2" :key="index" v-if="select.selected">
                {{select.name}}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{select.filter_count}}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
          </div>
          <div class="col-lg-2 col-md-6">
            <div class="label_algin_right text-underline m-2" style="color: #848484; cursor: pointer;" @click="unSelectAllFilter()">ล้างทั้งหมด</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-4 text-right">
      <b-button size="md" variant="primary" :to="'/location/add'" class="style_btn" v-if="permission.actionData.flag_insert"><i class="fa fa-plus"></i></b-button>
      <b-button size="md" variant="danger" v-b-modal.dialog_location_remove :disabled="checkDisabledDelete()" class="style_btn" v-if="permission.actionData.flag_delete"><i class="far fa-trash-alt"></i></b-button>
     </div>
      <div>
      <div class="card custom-card">
        <div class="card-body">
          <div class="table-responsive padding_top_20">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center" >
                  <th style="min-width: 50px; width: 1px;">เลือก</th>
                  <th @click="sortHandler('name')">ชื่อ
                    <div class="float-right" v-if="criteria.orderBy=='name'"><i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i></div>
                  </th>
                  <th @click="sortHandler('type_name')">ประเภทสถานที่ตั้ง
                    <div class="float-right" v-if="criteria.orderBy=='type_name'"><i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i></div>
                  </th>
                  <th @click="sortHandler('address')">ที่อยู่
                    <div class="float-right" v-if="criteria.orderBy=='address'"><i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i></div>
                  </th>
                  <th @click="sortHandler('city_name')">จังหวัด
                    <div class="float-right" v-if="criteria.orderBy=='city_name'"><i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i></div>
                  </th>
                  <th @click="sortHandler('district_name')">เขต/อำเภอ
                    <div class="float-right" v-if="criteria.orderBy=='district_name'"><i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i></div>
                  </th>
                  <th @click="sortHandler('subdistrict_name')">แขวง/ตำบล
                    <div class="float-right" v-if="criteria.orderBy=='subdistrict_name'"><i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i></div>
                  </th>
                  <th style="min-width: 50px; width: 1px;">จำนวน Supplier</th>
                  <th style="min-width: 50px; width: 1px;">ดำเนินการ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in items"
                    :key="data.id"
                    :id="`checkbox_location_index${index}`">
                  <td class="text-center checkbox_style" >
                    <b-form-checkbox @change="selectedLocation(...arguments, data, index)"
                                    :name="`checkbox_location${data.id}`"
                                    v-model="data.selected" />
                  </td>
                  <td>{{data.name}}</td>
                  <td>{{data.type_name}}</td>
                  <td>{{data.address}}</td>
                  <td>{{data.city_name}}</td>
                  <td>{{data.district_name}}</td>
                  <td>{{data.subdistrict_name}}</td>
                  <td class="text-center"><span title="Tooltip on top">{{data.locations.length}}</span></td>
                  <td class="text-center" >
                    <span class="style_icon_btn" v-if="permission.actionData.flag_update">
                      <i class="fas fa-pen" @click="onEditHandler(data.id)" title="แก้ไข"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="items.length === 0" class="text-center">
                  <td colspan="8">ไม่มีข้อมูล</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="padding_top_20 row">
        <div  class="col-lg-1 col-md-2 col-3"
              style="margin-top: 30px;">
          <select class="form-control mb-2"
                  id="pageLimit"
                  style="width: 80px;"
                  v-model="paging.limit"
                  v-on:change="changeLimit()">
            <option v-for="(data) in paging.pages"
                    :key="data"
                    :value="data">
              {{data}}
            </option>
          </select>
        </div>
        <div class="col-11">
          <div class="text-right paging_footer" v-if="paging.rowTotal > 0">
            <span class="display-total-paging" style="font-size: 12px; top: 5px;
                  font-family: Sarabun-Light;">แสดงรายการที่ {{((paging.page-1)*paging.limit)+1}} ถึง {{(paging.page) * paging.limit}} จาก {{paging.rowTotal | numberIntFormat}} รายการ</span>
            <paginate v-model="paging.page"
                      :first-last-button="true"
                      :page-count="paging.pageTotal"
                      :page-range="5"
                      :margin-pages="2"
                      :prev-text="'&lt;'"
                      :next-text="'&gt;'"
                      :first-button-text="'&lt;&lt;'"
                      :last-button-text="'&gt;&gt;'"
                      :click-handler="changePage"
                      :container-class="'pagination pagination-sm float-right'"
                      :page-class="'page-link'"
                      :prev-class="'page-link'"
                      :next-class="'page-link'">
            </paginate>
            <span class="display-total-paging" style="font-size: 12px; top: 5px; font-family: Sarabun-Light; margin-left: 10px;">
              Go to page
            </span>
            <Select2 v-model="paging.page" :options="paging.pageSizes" @change="changePage(paging.page)"/>
          </div>
          <!-- <div  class="text-right"
                v-if="paging.rowTotal > 0">
              <label class=""
                  style="margin: auto; font-size: 12px;
                  font-family: Sarabun-Light;
                  margin-right: 12px;">
                แสดงรายการที่ <strong>{{((paging.page-1)*paging.limit)+1}} ถึง {{(paging.page) * paging.limit}}</strong> จาก <strong>{{paging.rowTotal | numberIntFormat}} รายการ </strong>
              </label>
              <paginate v-model="paging.page"
                        :first-last-button="true"
                        :page-count="paging.pageTotal"
                        :page-range="5"
                        :margin-pages="2"
                        :prev-text="'&lt;'"
                        :next-text="'&gt;'"
                        :first-button-text="'&verbar;&lt;'"
                        :last-button-text="'&gt;&verbar;'"
                        :click-handler="changePage"
                        :container-class="'pagination pagination-sm float-right m-auto paging_ul'"
                        :page-class="'page-link'"
                        :prev-class="'page-link prev_paging'"
                        :next-class="'page-link next_paging'">
              </paginate>
            <div class="m-3 text-left">
              <label style="font-size: 12px;
                    font-family: Sarabun-Light;"
                    class="form-label">
                Go to page
              </label>
              <Select2 v-model="paging.page" :options="paging.pageSizes"/>
              <b-input-group size="sm">
                <b-form-input v-model="page"
                              v-number-only
                              class="form_control_not input_border_radius_left_top_bt">
                </b-form-input>

                <b-input-group-append >
                  <b-input-group-text style="border-top-right-radius: 2px;
                                      border-bottom-right-radius: 2px;
                                      border-left: 2px solid #dddd;">
                    <i class="fas fa-chevron-right"></i>
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div>
      <dialogLocationRemove  :items="items" @successFuction="initialData"></dialogLocationRemove>
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
import config from '../../../util/config';
import dialogLocationRemove from './dialog.location.remove';
export default {
  name: 'location-view-page-location',
  data () {
    return {
      permission:{
        appSlug: 'location',
        actionData: {}
      },
      selected: [],
      criteria: {
        search: "",
        orderBy: "",
        ascDesc: "",
        location_type_filters: [],
        city_filters: [],
        district_filters: [],
        subdistrict_filters: []
      },
      defaultCityFilters: [],
      defaultDistrictFilters: [],
      defaultSubDistrictFilters: [],
      items: [],
      paging: {
        page: 1,
        limit: config.pagination.limit,
        pages: config.pagination.pages,
        rowTotal: 0,
        pageTotal: 0,
        pageSizes: []
      }
    };
  },
  props: [],
  methods: {
    onEditHandler(id){
      this.SessionStorageUtils.setSession("page-location", id);
      this.$router.push(`/location/edit/${id}`);
    },
    selectedLocation (value, obj, indx) {
      console.log('selectedLocation ', value, obj)
      if (value) {
        this.setSelectedColumn(obj);
        $(`tr#checkbox_location_index${indx}`).attr('class', 'highlight');
      } else {
        console.log('selected ', this.selected)
        const index = this.selected.indexOf(obj);
        if (index > -1) {
          this.selected.splice(index, 1);
        }
        console.log('selected 1', this.selected)
        $(`tr#checkbox_location_index${indx}`).removeClass('highlight');
      }
    },
    setSelectedColumn (obj) {
      this.selected.push(obj)
      console.log('selected ', this.selected)
    },
    /******* local function ******/
    changeLimit(){
        this.paging.page = 1;
      this.getListItems();
    },
    changePage (page) {
      this.paging.page = parseInt(""+page);
      this.getListItems();
    },
    sortHandler(orderBy){
      if(orderBy!=this.criteria.orderBy){
        this.criteria.ascDesc = "asc";
      }else{
        this.criteria.ascDesc = this.criteria.ascDesc=="asc"?"desc":"asc";
      }
      this.criteria.orderBy = orderBy;
      this.getListItems();
    },
    searchHandler(){
      this.paging.page = 1;
      this.getListItems();
    },
    async getListItems () {
      let filters = [];
      this.criteria.location_type_filters.map((v)=>{
        if(v.selected){
          filters.push(v.id);
        }
      });
      filters = filters.join(",");
      let cityFilters = [];
      this.defaultCityFilters.map((v)=>{
        if(v.selected){
          cityFilters.push(v.id);
        }
      });
      cityFilters = cityFilters.join(",");
      let districtFilters = [];
      this.defaultDistrictFilters.map((v)=>{
        if(v.selected){
          districtFilters.push(v.id);
        }
      });
      districtFilters = districtFilters.join(",");
      let subDistrictFilters = [];
      this.defaultSubDistrictFilters.map((v)=>{
        if(v.selected){
          subDistrictFilters.push(v.id);
        }
      });
      subDistrictFilters = subDistrictFilters.join(",");
      let params = `search=${this.criteria.search}&location_type_filters=${filters}&city_filters=${cityFilters}&district_filters=${districtFilters}&subdistrict_filters=${subDistrictFilters}`;
      params += `&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
      const result = await this.HttpServices.getData(`/ab_location?${params}`);
      if(result&&result.status.code=="200"){
        const data = result.data;
        var total = Math.ceil(data.rowTotal/this.paging.limit);
        this.paging.rowTotal = data.rowTotal;
        this.paging.pageTotal = total;
        this.items = data.data;
        this.paging.pageSizes = [];
        for(let i=1;i<=total;i++){
          this.paging.pageSizes.push({id: i, text: i});
        }
      }else{
        this.paging.pageSizes = [];
        this.paging.rowTotal = 0;
        this.items = [];
      }
    },
    checkDisabledDelete(){
      let resp = true;
      for(const v of this.items){
        if(v.selected){
          resp = false;
          break;
        }
      }
      return resp;
    },
    async getFilterGroups(){
      const result = await this.HttpServices.getData(`/ab_location/getCountByGroup`);
      if(result&&result.status.code=="200"){
        const data = result.data;
        this.criteria.location_type_filters = data.location_types;
        this.criteria.city_filters = data.cities;
        this.criteria.district_filters = data.districts;
        this.criteria.subdistrict_filters = data.subdistricts;
        this.defaultCityFilters = this.criteria.city_filters;
        this.defaultDistrictFilters = this.criteria.district_filters;
        this.defaultSubDistrictFilters = this.criteria.subdistrict_filters;
      }
    },
    selectCity(item){
      this.searchHandler();
    },
    selectDistrict(item){
      this.searchHandler();
    },
    selectSubDistrict(item){
      this.searchHandler();
    },
    countFilter(){
      const filters = this.criteria.location_type_filters.filter((v)=>v.selected);
      const filtersCity = this.criteria.city_filters.filter((v)=>v.selected);
      const filtersDistrict = this.criteria.district_filters.filter((v)=>v.selected);
      const filtersSubDistrict = this.criteria.subdistrict_filters.filter((v)=>v.selected);
      return filters.length+filtersCity.length+filtersDistrict.length+filtersSubDistrict.length;
    },
    unSelectFilter(item){
      item.selected = false;
      this.searchHandler();
    },
    unSelectAllFilter(){
      this.criteria.location_type_filters.map((v)=>{
        v.selected = false;
      });
      this.criteria.city_filters.map((v)=>{
        v.selected = false;
      });
      this.criteria.district_filters.map((v)=>{
        v.selected = false;
      });
      this.criteria.subdistrict_filters.map((v)=>{
        v.selected = false;
      });
      this.searchHandler();
    },
    filterCity(e){
      const name = e.target.value;
      const cities = this.defaultCityFilters.filter((v)=>v.name.toLowerCase().indexOf(name)>-1);
      this.criteria.city_filters = cities;
    },
    filterDistrict(e){
      const name = e.target.value;
      const districts = this.defaultDistrictFilters.filter((v)=>v.name.toLowerCase().indexOf(name)>-1);
      this.criteria.district_filters = districts;
    },
    filterSubDistrict(e){
      const name = e.target.value;
      const subdistricts = this.defaultSubDistrictFilters.filter((v)=>v.name.toLowerCase().indexOf(name)>-1);
      this.criteria.subdistrict_filters = subdistricts;
    },
    async initialData(){
      this.getFilterGroups();
      this.getListItems();
    }
    /******* local function ******/
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    await this.initialData();
    this.SessionStorageUtils.deleteSession("page-location");
  },
  components: {
    dialogLocationRemove : dialogLocationRemove
  }
}
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }
</style>
