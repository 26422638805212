<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-map-marked-alt"></i> ข้อมูลสถานที่ตั้ง</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <page-location />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pageLocation from './view/page.location';
export default {
  name: 'location-index',
  methods: {},
  components: {
    pageLocation
  }
}
</script>
